// src/components/ProfileForm.js

import React, { useState } from 'react';
import axios from 'axios';

const ProfileForm = () => {
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    company: '',
    // tambahkan field lainnya sesuai kebutuhan
  });

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/api/profile/', profile)
      .then((response) => {
        alert('Profile saved successfully!');
      })
      .catch((error) => {
        console.error('There was an error saving the profile!', error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Name:
        <input type="text" name="name" value={profile.name} onChange={handleChange} />
      </label>
      <br />
      <label>
        Email:
        <input type="email" name="email" value={profile.email} onChange={handleChange} />
      </label>
      <br />
      <label>
        Company:
        <input type="text" name="company" value={profile.company} onChange={handleChange} />
      </label>
      <br />
      {/* tambahkan field lainnya */}
      <button type="submit">Save Profile</button>
    </form>
  );
};

export default ProfileForm;
