// src/pages/Profile.js

import React from 'react';
import ProfileForm from '../components/ProfileForm';

const Profile = () => (
  <div>
    <h2>Profile</h2>
    <ProfileForm />
  </div>
);

export default Profile;
