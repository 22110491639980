// src/pages/Home.js

import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => (
  <div>
    <h1>Welcome to Odin</h1>
    <nav>
      <ul>
        <li><Link to="/profile">Profile</Link></li>
        <li><Link to="/jobs">Jobs</Link></li>
        <li><Link to="/tenders">Tenders</Link></li>
        <li><Link to="/recommendations">Recommendations</Link></li>
      </ul>
    </nav>
  </div>
);

export default Home;
