// src/components/RecommendationList.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const RecommendationList = () => {
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    axios.get('/api/recommendations/')
      .then((response) => {
        setRecommendations(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the recommendations!', error);
      });
  }, []);

  return (
    <div>
      <h2>Recommendations</h2>
      <ul>
        {recommendations.map((recommendation) => (
          <li key={recommendation.id}>
            <h3>{recommendation.title}</h3>
            <p>{recommendation.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecommendationList;
