// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Jobs from './pages/Jobs';
import Tenders from './pages/Tenders';
import Recommendations from './pages/Recommendations';

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/tenders" element={<Tenders />} />
      <Route path="/recommendations" element={<Recommendations />} />
    </Routes>
  </Router>
);

export default App;
