// src/components/TenderList.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TenderList = () => {
  const [tenders, setTenders] = useState([]);

  useEffect(() => {
    axios.get('/api/tenders/')
      .then((response) => {
        setTenders(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the tenders!', error);
      });
  }, []);

  return (
    <div>
      <h2>Tenders</h2>
      <ul>
        {tenders.map((tender) => (
          <li key={tender.id}>
            <h3>{tender.title}</h3>
            <p>{tender.location}</p>
            <p>{tender.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TenderList;
