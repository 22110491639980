// src/pages/Recommendations.js

import React from 'react';
import RecommendationList from '../components/RecommendationList';

const Recommendations = () => (
  <div>
    <h2>Recommendations</h2>
    <RecommendationList />
  </div>
);

export default Recommendations;
