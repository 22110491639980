// src/pages/Jobs.js

import React from 'react';
import JobList from '../components/JobList';

const Jobs = () => (
  <div>
    <h2>Job Listings</h2>
    <JobList />
  </div>
);

export default Jobs;
