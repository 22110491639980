// src/pages/Tenders.js

import React from 'react';
import TenderList from '../components/TenderList';

const Tenders = () => (
  <div>
    <h2>Tenders</h2>
    <TenderList />
  </div>
);

export default Tenders;
